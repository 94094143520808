<template>
  <div id="FloorInto">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuName" show-action placeholder="请输入学生姓名或学号查询" @search="onSearch">
        <template #action>
          <div @click="toQuery">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell style="padding: 10px 16px 8px 16px;">
          <template #title>
            <div class="title-text hidden-text-teacher">{{row.campusName}}｜{{row.floorName}}｜{{row.roomCode}}</div>
          </template>
          <template #label>
            <div class="room-div">
              <van-row>
                <van-col span="9">
                  <div class='item-style'>
                    <div class='item-style-border'>
                      <van-image fit="contain" :src='stuViewApi + row.stuNo' />
                    </div>
                  </div>
                  <div class="photo-text hidden-photo-text">
                    <span>{{row.gradeNo?row.gradeNo+'级':''}}</span><br />
                    <span>{{row.stuNo}}</span>
                  </div>
                </van-col>
                <van-col span="15">
                  <div class="custom-title hidden-text" @click="toStuDeatil(row)">
                    <span style="color:rgba(35, 171, 114, 1)">{{row.bedNo+"号床"}}</span>
                    <span style="color:rgba(50, 50, 51, 1)">{{'-'+row.stuName}}</span>
                  </div>
                  <div class="custom-centent  hidden-text ">
                    <van-icon name="apps-o" size="14" /><span style="margin-left:5px">{{row.stuSex +" | " +row.stuNationCode}}</span>
                  </div>
                  <div class="custom-centent  hidden-text ">
                    <svg-icon icon-class="bank-line" size="14" /><span style="margin-left:5px">{{row.collName}}</span>
                  </div>
                  <div class="custom-centent  hidden-text ">
                    <van-icon name="send-gift-o" size="14" /><span style="margin-left:5px">{{row.specName}}</span>
                  </div>
                  <div class="custom-centent  hidden-text ">
                    <van-icon name="sign" size="14" /><span style="margin-left:5px">{{row.className}}</span>
                  </div>
                </van-col>
              </van-row>
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.isRounding" class="audit success-color b-color-success">舍长</span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="custom-centent   hidden-text-teacher ">
              <van-icon name="phone-o" size="14" /><span style="margin-left:5px">学生电话: {{row.stuPhone}}</span>
            </div>
            <div class="custom-centent  hidden-text-teacher">
              <van-icon name="user-o" size="14" /><span style="margin-left:5px">辅导员: {{row.tutorName?row.tutorName:""}}{{row.tutorPhone?"("+row.tutorPhone+")":""}}</span>
            </div>
            <div class="custom-centent  hidden-text-teacher ">
              <van-icon name="manager-o" size="14" /><span style="margin-left:5px">学院副书记: {{row.unitTeacherName?row.unitTeacherName:""}}{{row.unitTeacherPhone?"("+row.unitTeacherPhone+")":""}}</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { stuViewApi } from "@/config/settings";

export default {
  name: "FloorLayer",
  mixins: [initData],
  data() {
    return {
      value: "",
      stuViewApi,
    };
  },
  created() { },
  methods: {
    beforeInit() {
      this.url = "/api/into/noAuth";
      this.sort = ["roomCode,asc", "bedNo,asc", "gradeNo,desc"];

      if (parseInt(this.$route.query.type) === 1) {
        // 按照校区
        this.query.campusId = this.$route.query.campusName;
        this.query.stuName = this.$route.query.stuName;
      } else if (parseInt(this.$route.query.type) === 2) {
        // 按照公寓
        this.query.floorCode = this.$route.query.floorCode;
        this.query.stuName = this.$route.query.stuName;
      } else if (parseInt(this.$route.query.type) === 3) {
        // 按照楼层和楼宇查询
        this.query.floorCode = this.$route.query.floorCode;
        this.query.floorLayers = this.$route.query.layer;
        this.query.stuName = this.$route.query.stuName;
      } else if (parseInt(this.$route.query.type) === 4) {
        // 点击房间查询房间内的所有数据
        this.query.roomId = this.$route.query.id;
      }
      // 入住状态的学生
      this.query.statusIn = "1,3";
      return true;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toStuDeatil(data) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(data.stuNo) },
      });
    },
    onSearch() { },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/apartment/floor.scss';
::v-deep .van-image__img {
  border-radius: 12px;
}
</style>
